<template>
  <a-layout-sider
    :class="[
      theme,
      'sider',
      isMobile ? null : 'shadow',
      fixSiderbar ? 'ant-fixed-sidemenu' : null,
    ]"
    width="236px"
    :collapsible="collapsible"
    v-model="collapsed"
    :trigger="null"
  >
    <div :class="['logo', theme]">
      <router-link to="/">
        <img
          src="../../assets/img/logo3.svg"
          style="width: 120px;"
        />
        <!-- <h1 class="animated fadeIn">CHECKEY {{systemName}}</h1> -->
      </router-link>
    </div>
    <div v-show="visible" class="tab">
      <div class="head">
        <div class="my-flex my-item-jc-sb my-item-center" style="margin: 16px">
          <div
            style="
              color: #0b2850;
              font-family: SourceHanSansCN-Medium-Medium;
              font-size: 20px;
            "
          >
            个人中心
          </div>
          <img src="../../assets/img/close.svg" @click="visible = false">
        </div>
        <a-divider style="background: #e0eaf3" />
        <div style="overflow: scroll; height: 92%">
          <div
            style="width: 148px; margin-left: 134px; margin-top: 28px"
            class="my-flex-column my-item-center"
          >
            <!-- <a-avatar
              class="avatar"
              :size="64"
              shape="circle"
              :src="this.user.avatar"

            /> -->
            <div @click="updateAvatar" class="circle">
              <img :src="this.user.avatar" class="circle0" />
              <div class="circle1">修改头像</div>
            </div>
            <update-avatar
              @cancel="handleUpdateAvatarCancel"
              @success="handleUpdateAvatarSuccess"
              :user="user"
              ref="avatarRef"
              :updateAvatarModelVisible="updateAvatarModelVisible"
            >
            </update-avatar>
            <div
              style="
                color: #13a8bf;
                font-family: SourceHanSansCN-Bold-Bold;
                font-size: 14px;
                line-height: 18px;
                font-weight: bold;
              "
            >
              {{ this.user.nickname }}
            </div>
            <div
              style="
                color: #0b2850;
                font-family: SourceHanSansCN-Regular-Regular;
                font-size: 12px;
                line-height: 18px;
              "
            >
              {{ this.user.email }}
            </div>
          </div>
          <div style="margin: 40px">
            <update-profile
              ref="updateProfile"
              v-show="profile"
              @changeMassege="changeMassege"
            ></update-profile>
            <update-profile1
              ref="updateProfile1"
              v-show="!profile"
              @updateProfile="updateProfile"
            ></update-profile1>
            <a-divider style="background: #e0eaf3" />
            <div v-show="!updatePasswordModelVisible">
              <div class="my-flex my-item-jc-sb" style="margin-top: 37px">
                <div
                  style="
                    font-family: SourceHanSansCN-Bold-Bold;
                    font-size: 18px;
                    color: #0b2850;
                  "
                >
                  密码管理
                </div>
                <a-button
                  shape="round"
                  size="small"
                  style="
                    border-radius: 32px !important;
                    color: #13a8bf;
                    border: 1px solid #13a8bf;
                    padding: 6px 20px;
                    width: 96px;
                    height: 32px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                  @click="updatePassword"
                  >修改密码</a-button
                >
              </div>
              <div style="margin-top: 21px">
                <span>*</span><span>旧密码</span>
              </div>
              <div
                style="
                  border-radius: 8px;
                  background: #f3f6ff;
                  padding: 14px 16px;
                  margin-top: 11.5px;
                "
              >
                ***********
              </div>
            </div>
            <update-password
              v-show="updatePasswordModelVisible"
              @success="handleUpdate"
              @cancel="handleCancelUpdate"
              :user="user"
              :updatePasswordModelVisible="updatePasswordModelVisible"
            >
            </update-password>
          </div>
        </div>
      </div>
      <div
        style="
          height: 100px;
          background: rgb(244, 246, 255);
          cursor: pointer;
          padding-left: 42.8px;
          flex-grow: 1;
        "
        class="my-flex"
        @click="logout"
      >
        <a-icon type="logout" style="margin-top: 10%;color: rgba(0, 0, 0, 0.65)" />
        <span style="margin-top: 9%">退出登录</span>
      </div>
    </div>
    <i-menu
      :theme="theme"
      :collapsed="collapsed"
      :menuData="menuData"
      @select="onSelect"
      style="font-size: 16px;"
    />
    <div style="width: 236px; height: 96px; position: fixed; bottom: 0px">
      <a-divider />
      <div
        @click="showDrawer"
        class="my-flex my-item-center my-item-jc-center"
        style="margin-top: 17px; cursor: pointer"
      >
        <a-avatar class="avatar" :size="40" shape="circle" :src="avatar" />
        <div style="margin-left: 10px">
          <div
            style="
              color: #13a8bf;
              font-family: Source Han Sans CN;
              font-size: 14px;
              font-weight: bold;
            "
          >
            {{ this.user.nickname }}
          </div>
          <div
            style="
              color: #0b2850;
              font-family: Source Han Sans CN;
              font-size: 12px;
            "
          >
            {{ this.user.email }}
          </div>
        </div>
      </div>
    </div>
  </a-layout-sider>
</template>

<script>
import IMenu from './menu'
import UpdateAvatar from '../../views/personal/UpdateAvatar2'
import UpdateProfile from '../../views/personal/UpdateProfile.vue'
import UpdateProfile1 from '../../views/personal/UpdateProfile1.vue'
import UpdatePassword from '../../views/personal/UpdatePassword'
import { mapMutations, mapState } from 'vuex'

import { Icon } from 'ant-design-vue'
const Fonts = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_3408722_1zydq25n801.js'
})

export default {
  name: 'SiderMenu',
  components: {
    IMenu,
    UpdateProfile,
    UpdateProfile1,
    UpdateAvatar,
    UpdatePassword,
    Fonts
  },
  props: {
    collapsible: {
      type: Boolean,
      required: false,
      default: false
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false
    },
    menuData: {
      type: Array,
      required: true
    },
    theme: {
      type: String,
      required: false,
      default: 'dark'
    }
  },
  data () {
    return {
      visible: false,
      profile: true,
      updateAvatarModelVisible: false,
      updatePasswordModelVisible: false
    }
  },
  async mounted () {
    await this.getUserInfo()
    console.log(this.avatar)
    this.updateProfile1()
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.setting.isMobile,
      systemName: (state) => state.setting.systemName,
      fixSiderbar: (state) => state.setting.fixSiderbar,
      settingBar: (state) => state.setting.settingBar.opened,
      user: (state) => state.account.user
    }),
    avatar () {
      return this.user.avatar
    }
  },
  methods: {
    updatePassword () {
      this.updatePasswordModelVisible = true
    },
    handleCancelUpdate () {
      this.updatePasswordModelVisible = false
    },
    handleUpdate () {
      this.updatePasswordModelVisible = false
      this.$message.success('更新密码成功，请重新登录系统')
      setTimeout(() => {
        this.logout()
      }, 1500)
    },
    ...mapMutations({
      setUser: 'account/setUser'
    }),
    onSelect (obj) {
      this.$emit('menuSelect', obj)
    },
    afterVisibleChange (val) {
      console.log('visible', val)
    },
    showDrawer () {
      this.visible = true
    },
    onClose () {
      this.visible = false
    },
    logout () {
      if (this.user.userType === 0) {
        this.$db.clear()
        this.$router.push('/login')
        return
      }
      this.$get(`logout/${this.user.id}`)
        .then(() => {
          return new Promise((resolve, reject) => {
            this.$db.clear()
            location.href = 'https://www.checkey.net/#/nWebHome?locale=cn'
            // location.href = 'https://www.checkey.net/#/webHome'
            // location.reload();
          })
        })
        .catch(() => {
          this.$message.error('退出系统失败')
        })
    },
    getUserInfo () {
      this.$get('web/user/selectUserBasicInfo')
        .then((r) => {
          const userInfo = r.data.data
          if (!userInfo.isInitPassword) {
            this.visiable = true
          }
        })
        .catch(() => {})
    },
    async updateProfile (user) {
      this.$refs.updateProfile.setFormValues(user)
      this.profile = true
    },
    updateProfile1 () {
      this.$refs.updateProfile.setFormValues(this.user)
    },
    changeMassege () {
      console.log('zdrceecv')
      this.profile = false
      this.$refs.updateProfile1.setFormValues(this.user)
    },
    handleUpdateAvatarCancel () {
      this.$refs.avatarRef.updateAvatarModelVisible = false
    },
    handleUpdateAvatarSuccess (avatar) {
      this.$refs.avatarRef.updateAvatarModelVisible = false
      this.$message.success('更换头像成功')
      const user = this.user
      user.avatar = avatar
      this.setUser(user)
    },
    updateAvatar () {
      this.$refs.avatarRef.updateAvatarModelVisible = true
      this.$refs.avatarRef.setList()
      this.userId = this.user.userId
    }
  }
}
</script>

<style lang="less" scoped>
.shadow {
  box-shadow: 1px 0 6px rgba(0, 21, 41, 0.35);
}

.sider {
  z-index: 16;
  position: relative;
  overflow-x: hidden;

  &.light {
    background-color: #fff;
  }

  &.dark {
    background-color: #393e46;
  }

  &.ant-fixed-sidemenu {
    position: fixed;
    overflow-y: auto;
    height: 100%;
  }

  .logo {
    // height: 59px;
    position: relative;
    line-height: 59px;
    left: 57.98px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    overflow: hidden;
    display: flex;

    &.light {
      // background-color: #fff;
      // background-color: #002853;
      border-bottom: 1px solid #f8f8f8;

      h1 {
        color: #fff;
      }
    }

    &.dark {
      background-color: #393e46;

      h1 {
        color: #fff;
      }
    }

    h1 {
      color: #fff;
      font-size: 15px;
      margin: 0 0 0 12px;
      font-family: Chinese Quote, -apple-system, BlinkMacSystemFont, Segoe UI,
        PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue,
        Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
        Segoe UI Symbol;
      font-weight: 600;
      display: inline-block;
      height: 32px;
      line-height: 32px;
      vertical-align: middle;
    }

    img {
      width: 32px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.tab {
  width: 416px;
  height: 100%;
  position: fixed;
  margin-left: 236px;
  box-shadow: 0px 0px 20px 5px rgba(112, 144, 176, 0.12);
  border-radius: 24px 24px 0px 0px;
  display: flex;
  flex-direction: column;
  top: 0;
}

.head {
  overflow: hidden;
  background: #fff;
  border-radius: 24px 24px 0px 0px;
  height: 92%;
}

.ant-divider-horizontal {
  margin-top: 24px;
  margin-bottom: 0px;
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 5px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.1);
}
.circle {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  overflow: hidden;
}
.circle0 {
  width: 100%;
  height: 100%;
}
.circle1 {
  width: 100%;
  height: 100%;
  background: #13a8bf;
  opacity: 0.6; //遮罩层的透明度
  transition: transform 1s ease;
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  font-family: SourceHanSansCN-Regular-Regular;
}
.circle:hover > .circle1 {
  transform: translateY(-30%); //位置移动
}
</style>
<style lang="less" scoped>
/deep/ .ant-menu-root.ant-menu-inline{
 margin-top: 40px;
}
/deep/ .anticon {
  color: #13a8bf;
  font-size: 16px;
}
/deep/ .ant-menu-item-selected > a {
  color: rgba(0, 0, 0, 0.65);
}
/deep/ .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-row-hover,
/deep/ .ant-table-row-hover > td {
  background-color: rgb(239, 242, 245) !important;
  color: #13a8bf;
}
/deep/ .ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #13a8bf !important;
}
/deep/ .ant-menu-item-selected {
  background: #f5fcfd !important;
  color: #000;
}
/deep/ .ant-menu-item-selected > a:hover {
  color: #13a8bf;
}
/deep/ .ant-menu-item-active {
  background-color: #fff !important;
  color: #13a8bf !important;
}

/deep/ .ant-menu-item > a:hover {
  color: #13a8bf !important;
}

/deep/ .ant-menu-submenu-title:hover {
  color: #13a8bf !important;
}

/deep/ .ant-menu-submenu-selected {
  color: #13a8bf !important;
}

/deep/ .ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before {
  background: linear-gradient(to right, #13a8bf, #13a8bf);
}
/deep/ .ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before {
  background: linear-gradient(to right, #13a8bf, #13a8bf);
}

/deep/ .ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after {
  background: linear-gradient(to right, #13a8bf, #13a8bf);
}

/deep/ .ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after {
  background: linear-gradient(to right, #13a8bf, #13a8bf);
}

/deep/ .ant-menu-submenu-open.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateX(2px);
}

/deep/ .ant-menu-submenu-open.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateX(-2px);
}
</style>
